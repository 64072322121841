import './Header.scss';
import { Link } from 'react-router';
import * as api from '../../api/api';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

function Header() {
  const [headerContent, setHeaderContent] = useState({});
  const [announceIsActive, setAnnounceIsActive] = useState(false);
  const navRef = useRef(null);
  const [accoutMouseEnter, setAccountMouseEnter] = useState(false);

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const announceRef = useRef(null);
  useEffect(() => {
    (async function () {
      setHeaderContent(JSON.parse((await api.getHeaderContent()).content));
    })();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        announceIsActive &&
        announceRef.current &&
        !announceRef.current.contains(event.target)
      ) {
        setAnnounceIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [announceIsActive]);

  const clickAccontLinkHandler = () => {
    setAnnounceIsActive(true);
  };

  const handleMouseEnter = () => {
    if (!isSmallScreen) {
      if (navRef.current) {
        setAccountMouseEnter(true);
      }
    }
  };

  const handleMouseLeave = () => {
    if (!isSmallScreen) {
      if (navRef.current) {
        setAccountMouseEnter(false);
      }
    }
  };

  return (
    <header className="header">
      <div className="header__wrapper">
        <Link to="/" className="header__logo">
          <img
            src={
              process.env.REACT_APP_DOMAIN_NAME + '/' + headerContent.logo_src
            }
            alt="Логотип Научные Меридианы России"
          />
        </Link>
        <nav
          className={
            accoutMouseEnter ? 'header__nav header__nav--navy' : 'header__nav'
          }
          ref={navRef}
        >
          <div className="header__nav-item  header__about">
            <Link to="/#about">О проекте</Link>
          </div>
          <div
            onClick={() => clickAccontLinkHandler()}
            className="header__nav-item  header__account"
          >
            <Link to="#">
              <span className="header__nav-item-text">Моя страница</span>
              <img
                className="header__nav-icon"
                src="./../img/icons/icon-account.svg"
                alt="Личный кабинет"
              ></img>
            </Link>
            <div
              ref={announceRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={
                announceIsActive
                  ? 'header__announce is-active'
                  : 'header__announce'
              }
            >
              <p>
                Пока в&nbsp;разработке, но&nbsp;скоро здесь будет много
                интересного!
              </p>
            </div>
          </div>
          {/* {headerContent.menu_items?.map((item, index) => (
            <div
              key={item.name}
              className={
                index === 0
                  ? 'header__nav-item  header__about'
                  : 'header__nav-item  header__account'
              }
            >
              <Link to={item.href}>
                <span className={item.icon ? 'header__nav-item-text' : ''}>
                  {item.name}
                </span>
                {item.icon ? (
                  <img
                    className="header__nav-icon"
                    src="./../img/icons/icon-account.svg"
                    alt="Личный кабинет"
                  ></img>
                ) : (
                  ''
                )}
              </Link>
            </div>
          ))} */}
        </nav>
      </div>
    </header>
  );
}

export default Header;
