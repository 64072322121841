import { useEffect, useRef, useState } from 'react';
import * as api from '../../api/api';
import './Filters.scss';
import CustomSelect from '../CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';

function Filters({
  selectedScientificField,
  setSelectedScientificField,
  filterBtnIsActive,
}) {
  const { categories, selectedCategory } = useSelector(
    (state) => state.categories
  );

  const [scientificFields, setScientificFields] = useState([]);

  function transformToOptions(arr) {
    return arr.map((obj) => {
      return { value: obj.id, label: obj.name, icon: obj.icon, fill: obj.fill };
    });
  }

  const resetFilters = () => {
    setSelectedScientificField({
      value: 'all',
      label: 'Все области науки',
    });
  };

  useEffect(() => {
    (async function () {
      setScientificFields(transformToOptions(await api.getScientificFields()));
    })();
  }, []);
  return (
    <div className={filterBtnIsActive ? 'filters is-active' : 'filters'}>
      <div className="filters__columns">
        <div className="filters__category">
          <CustomSelect
            selectedCategory={selectedCategory}
            options={categories}
            name="categories"
          />
        </div>
        <div
          className={
            selectedCategory?.value === 'discoveries'
              ? 'filters__scientific-fields is-active'
              : 'filters__scientific-fields'
          }
        >
          <CustomSelect
            selectedScientificField={selectedScientificField}
            setSelectedScientificField={setSelectedScientificField}
            options={scientificFields}
            initialSelect="Все области науки"
            name="scientificFields"
          />
          <button
            className={
              selectedScientificField?.value === 'all'
                ? 'filters__reset-btn'
                : 'filters__reset-btn is-active'
            }
            onClick={resetFilters}
          >
            <span>Сбросить фильтр</span>
            <img src="./../img/icons/icon-close.svg" alt="Сброс фильтра" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
