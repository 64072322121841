import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../api/api';

export const fetchDiscoveries = createAsyncThunk(
  'broadcasts/fetchDiscoveries',
  async function () {
    return await api.getItems();
  }
);

const discoveriesSlice = createSlice({
  name: 'discoveries',
  initialState: {
    discoveries: [],
    status: null,
    error: null,
    isHidden: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscoveries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.discoveries = [];
      })
      .addCase(fetchDiscoveries.fulfilled, (state, action) => {
        state.discoveries = action.payload;
        state.status = 'resolved';
      })
      .addCase(fetchDiscoveries.rejected, (state) => {
        state.status = 'rejected';
        state.discoveries = [];
      });
  },
});

export default discoveriesSlice.reducer;
