import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from './../api/api';

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async function (formData) {
    const data = await api.login({
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    return data;
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async function () {
    const data = await api.logout({
      method: 'GET',
      credentials: 'include',
    });
    return data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      id: null,
      login: null,
      token: localStorage.getItem('token') || null,
      role: localStorage.getItem('role') || null,
    },
    authErrorMessage: null,
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'resolved';
        if (action.payload.status === false) {
          state.authErrorMessage = action.payload.message;
          state.user.token = null;
          state.user.role = null;
        } else {
          state.user.token = action.payload.access_token;
          state.user.role = action.payload.role;
          state.authErrorMessage = null;
          localStorage.setItem('token', action.payload.access_token);
          localStorage.setItem('role', action.payload.role);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        if (action.payload.status === true) {
          state.user.token = null;
          state.user.role = null;
          localStorage.removeItem('token');
          localStorage.removeItem('role');
        }
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
