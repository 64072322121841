import { useEffect, useState } from 'react';
import './MapEditor.scss';
import MapEdit from '../Map/MapEdit';
import * as api from '../../api/api';
import MapEdit2 from '../Map/MapEdit2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities } from '../../store/citiesSlice';

function MapEditor() {
  const dispatch = useDispatch();
  const [scientificFields, setScientificFields] = useState([]);
  const [category, setCategory] = useState('broadcast');
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);
  const [scientificField, setScientificField] = useState(1);
  const [isScientificFieldDisabled, setIsScientificFieldDisabled] =
    useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isSelectCityDisabled, setIsSelectCityDisabled] = useState(false);
  const [citySelectValue, setCitySelectValue] = useState(null);
  const [cityBlockIsHidden, setCityBlockIsHidden] = useState(true);
  const [newCity, setNewCity] = useState('');
  const [coordinateX, setCoordinateX] = useState(null);
  const [coordinateY, setCoordinateY] = useState(null);
  const [isMapDisabled, setIsMapDisabled] = useState(true);
  const [isCardDisabled, setIsCardDisabled] = useState(true);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [videoLink, setVideoLink] = useState('');

  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);

  const { cities } = useSelector((state) => state.cities);

  useEffect(() => {
    dispatch(fetchCities());
  }, []);

  useEffect(() => {
    setCitySelectValue(cities[0]?.id);
  }, [cities]);

  useEffect(() => {
    async function fetchScientificFields() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/all_scientific_fields`
      );

      const data = await response.json();
      setScientificFields(data);
    }
    fetchScientificFields();
  }, []);

  const categorySelectHandler = (e) => {
    setCategory(e.target.value);
  };

  const confirmCategoryHandler = () => {
    if (category === 'broadcast') {
      setIsCategoryDisabled(true);
      setIsMapDisabled(false);
    } else if (category === 'discovery') {
      setIsCategoryDisabled(true);
      setIsScientificFieldDisabled(false);
    }
  };

  const scientificFieldSelectHandler = (e) => {
    setScientificField(e.target.value);
  };

  const confirmScientificFieldHandler = () => {
    setIsScientificFieldDisabled(true);
    setIsCityDisabled(false);
  };

  const citySelectHandler = (e) => {
    setCitySelectValue(e.target.value);
  };

  const addCityBtnHandler = () => {
    setCityBlockIsHidden(false);
    setIsSelectCityDisabled(true);
  };

  const cityHandler = (e) => {
    setNewCity(e.target.value);
  };

  const confirmCityHandler = () => {
    setIsCityDisabled(true);
    if (isSelectCityDisabled && newCity !== '') {
      setIsMapDisabled(false);
    } else {
      setIsCardDisabled(false);
    }
  };

  const confirmMapHandler = () => {
    setIsMapDisabled(true);
    setIsCardDisabled(false);
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const linkHandler = (e) => {
    setLink(e.target.value);
  };

  const videoLinkHandler = (e) => {
    setVideoLink(e.target.value);
  };

  const confirmCardHandler = (e) => {
    e.preventDefault();
    setIsCardDisabled(true);
    setIsAddBtnDisabled(false);
  };

  const addDotHandler = (e) => {
    e.preventDefault();

    if (category === 'broadcast') {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('link', link);
      formData.append('video_link', videoLink);
      formData.append('coordinateX', coordinateX);
      formData.append('coordinateY', coordinateY);

      (async function () {
        const result = await api.addBroadcast({
          method: 'POST',
          body: formData,
          credentials: 'include',
        });
        if (result.status === true) {
          setCoordinateX(null);
          setCoordinateY(null);
          setName('');
          setDescription('');
          setLink('');
          setVideoLink('');
          setIsAddBtnDisabled(true);
          setIsCategoryDisabled(false);
          setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }, 500);
        }
      })();
    } else if (category === 'discovery') {
      if (isSelectCityDisabled && newCity !== '') {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('link', link);
        formData.append('city_name', newCity);
        formData.append('video_link', videoLink);
        formData.append('coordinateX', coordinateX);
        formData.append('coordinateY', coordinateY);
        formData.append('scientific_field_id', scientificField);

        (async function () {
          const result = await api.addLocation({
            method: 'POST',
            body: formData,
          });
          if (result.status === true) {
            setCoordinateX(null);
            setCoordinateY(null);
            setName('');
            setDescription('');
            setLink('');
            setVideoLink('');
            setIsAddBtnDisabled(true);
            setIsCategoryDisabled(false);
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 500);
          }
        })();
      } else {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('link', link);
        formData.append('city_id', citySelectValue);
        formData.append('video_link', videoLink);
        formData.append('scientific_field_id', scientificField);

        (async function () {
          const result = await api.addLocation({
            method: 'POST',
            body: formData,
          });
          if (result.status === true) {
            setCoordinateX(null);
            setCoordinateY(null);
            setName('');
            setDescription('');
            setLink('');
            setVideoLink('');
            setIsAddBtnDisabled(true);
            setIsCategoryDisabled(false);
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 500);
          }
        })();
      }
    }
  };

  return (
    <div className="map-editor">
      <div
        className={
          isCategoryDisabled
            ? 'map-editor__select-category map-editor__step disabled'
            : 'map-editor__select-category map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">Шаг 1. Выбор категории</h2>
        <select
          name="categories"
          id="categories"
          onChange={(e) => categorySelectHandler(e)}
        >
          <option value="broadcast">Трансляция</option>
          <option value="discovery">Открытие</option>
        </select>
        <button
          className="map-editor__confirm-btn"
          onClick={confirmCategoryHandler}
        >
          Далее
        </button>
      </div>
      <div
        className={
          isScientificFieldDisabled
            ? 'map-editor__select-scientific-field map-editor__step disabled'
            : 'map-editor__select-scientific-field map-editor__step '
        }
      >
        <h2 className="map-editor__step-title">Шаг 2. Выбор области науки</h2>
        <select
          name="scientific-fields"
          id="scientific-fields"
          onChange={(e) => scientificFieldSelectHandler(e)}
        >
          {scientificFields.map((scientificField) => (
            <option key={scientificField.id} value={scientificField.id}>
              {scientificField.name}
            </option>
          ))}
        </select>
        <button
          className="map-editor__confirm-btn"
          onClick={confirmScientificFieldHandler}
        >
          Далее
        </button>
      </div>
      <div
        className={
          isCityDisabled
            ? 'map-editor__select-city map-editor__step disabled'
            : 'map-editor__select-city map-editor__step '
        }
      >
        <h2 className="map-editor__step-title">
          Шаг 3. Выбор населенного пункта
        </h2>
        <p className="map-editor__desc">
          Выберете населенный пункт из списка или добавьте новый
        </p>
        <div
          className={
            isSelectCityDisabled
              ? 'map-editor__select-city-select disabled'
              : 'map-editor__select-city-select'
          }
        >
          <select
            name="cities"
            id="cities"
            onChange={(e) => citySelectHandler(e)}
          >
            {cities?.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <p>Если населенного пункта нет в списке добавьте новый</p>
        <button
          className="map-editor__add-city-btn"
          onClick={addCityBtnHandler}
        >
          Добавить новый населенный пункт
        </button>
        <div
          className={
            cityBlockIsHidden
              ? 'map-editor__add-city'
              : 'map-editor__add-city is-active'
          }
        >
          <label>
            Населенный пункт
            <input
              type="text"
              placeholder="Введите название населенного пункта"
              value={newCity}
              onChange={(e) => cityHandler(e)}
            />
          </label>
        </div>
        <button
          className="map-editor__confirm-btn"
          onClick={confirmCityHandler}
        >
          Далее
        </button>
      </div>
      <div
        className={
          isMapDisabled
            ? 'map-editor__select-map map-editor__step disabled'
            : 'map-editor__select-map map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">
          Шаг 3. Установка точки на карту
        </h2>
        {/* <MapEdit
          setCoordinateX={setCoordinateX}
          setCoordinateY={setCoordinateY}
        /> */}
        <MapEdit2
          setCoordinateX={setCoordinateX}
          setCoordinateY={setCoordinateY}
        />
        <button className="map-editor__confirm-btn" onClick={confirmMapHandler}>
          Далее
        </button>
      </div>
      <div
        className={
          isCardDisabled
            ? 'map-editor__card map-editor__step disabled'
            : 'map-editor__card map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">Шаг 4. Наполнение карточки</h2>
        <form>
          <label>
            {' '}
            Название
            <input
              type="text"
              placeholder="Введите название"
              value={name}
              onChange={(e) => nameHandler(e)}
            />
          </label>
          <label>
            {' '}
            Описание
            <textarea
              name="description"
              id="description"
              placeholder="Добавьте краткое описание"
              value={description}
              onChange={(e) => descriptionHandler(e)}
            ></textarea>
          </label>
          <label>
            {' '}
            Ссылка на сообщество
            <input
              type="text"
              placeholder="Добавьте ссылку на сообщество"
              value={link}
              onChange={(e) => linkHandler(e)}
            />
          </label>
          <label>
            {' '}
            Ссылка на видео
            <input
              type="text"
              placeholder="Добавьте ссылку на видео"
              value={videoLink}
              onChange={(e) => videoLinkHandler(e)}
            />
          </label>
        </form>
        <button
          className="map-editor__confirm-btn"
          onClick={(e) => confirmCardHandler(e)}
        >
          Далее
        </button>
      </div>
      <button
        disabled={isAddBtnDisabled ? true : false}
        className="map-editor__confirm-btn map-editor__add-btn"
        onClick={(e) => addDotHandler(e)}
      >
        Добавить
      </button>
    </div>
  );
}

export default MapEditor;
