import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../api/api';

export const fetchBroadcasts = createAsyncThunk(
  'broadcasts/fetchBroadcasts',
  async function () {
    return await api.getBroadcasts();
  }
);

const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState: {
    broadcasts: [],
    status: null,
    error: null,
    isHidden: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBroadcasts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.broadcasts = [];
      })
      .addCase(fetchBroadcasts.fulfilled, (state, action) => {
        state.broadcasts = action.payload;
        state.status = 'resolved';
      })
      .addCase(fetchBroadcasts.rejected, (state) => {
        state.status = 'rejected';
        state.broadcasts = [];
      });
  },
});

export default broadcastsSlice.reducer;
