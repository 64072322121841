import './AdminLoginPage.scss';

import AdminAuth from '../../components/AdminAuth/AdminAuth';

function AdminLoginPage() {
  return (
    <section className="admin-login">
      <div className="container">
        {/* <div className="login__wrapper">
          <div className="login__auth"> */}
            <AdminAuth />
          {/* </div> */}
          {/* <div className="login__img">
            <img
              src="./../img/content/about/about-01.svg"
              alt="Научные меридианы России"
            />
          </div> */}
        {/* </div> */}
      </div>
    </section>
  );
}

export default AdminLoginPage;
