import { Routes, Route, NavLink } from 'react-router';
import * as api from '../../api/api';
import { useEffect } from 'react';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar';
import './AdminPage.scss';
import HeaderEditor from '../../components/HeaderEditor/HeaderEditor';
import MapEditor from '../../components/MapEditor/MapEditor';
import BroadcastsEditor from '../../components/BroadcastsEditor/BroadcastsEditor';
import DiscoveriesEditor from '../../components/DiscoveriesEditor/DiscoveriesEditor';

function UserPage() {
  useEffect(() => {
    (async function () {
      const response = await api.getUserInfo();
    })();
  });

  return (
    <div className="admin-page">
      <div className="container">
        <div className="admin-page__wrapper">
          <AdminSidebar />

          <div className="tabs-content">
            <Routes>
              <Route path="" element={<div>Добро пожаловать!</div>} />
              <Route path="logo" element={<HeaderEditor />} />
              <Route path="about" element={<div>ABOUT</div>} />
              <Route path="map" element={<MapEditor />} />
              <Route path="broadcasts" element={<BroadcastsEditor />} />
              <Route path="discoveries" element={<DiscoveriesEditor />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPage;
