import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import './Card.scss';

function Card({ cardIsActive, setCardIsActive, card }) {

  const decodeHtml = (html) => {
    const parser = new DOMParser();

    const decodedString = parser.parseFromString(html, "text/html").documentElement.textContent;

    return decodedString;
  }
  // const location = useLocation();
  // const navigate = useNavigate();
  // function handleShareTgBtn() {
  //   const path = window.location.href;

  //   const shareLink = `https://t.me/share/url?url=${path}&text=${card.name}`;

  //   window.open(shareLink, '_blank');
  // }

  // function handleShareVkBtn() {
  //   const path = window.location.href;
  //   const shareLink = `https://vk.com/share.php?url=${path}`;

  //   window.open(shareLink, '_blank');
  // }

  function handleCloseCard() {
    setCardIsActive(false);

    // navigate('/', { replace: true });
  }

  useEffect(() => {
    if (cardIsActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [cardIsActive]);
  return (
    <div className={cardIsActive ? 'card is-active' : 'card'}>
      <div className="card__top">
        <button type="button" className="card__btn" onClick={handleCloseCard}>
          <img src="./../img/icons/icon-close.svg" alt="Закрыть" />
        </button>
      </div>
      <div className="card__content">
        <div className="card__title">
          <div
            className="card__icon"
            dangerouslySetInnerHTML={{ __html: card.icon }}
            style={{ backgroundColor: card.fill }}
          ></div>
          <h2>{decodeHtml(card.name)}</h2>
        </div>
        <p className="card__desc">{decodeHtml(card.description)}</p>
        <a target="_blank" href={card.link} className="card__link">
          Ссылка на сообщество
        </a>
        {card.type === 'discovery' ? (
          <p className="card__subtitle">Видео</p>
        ) : (
          ''
        )}
        <div
          className="card__video"
          dangerouslySetInnerHTML={{ __html: card.video }}
        ></div>
      </div>
    </div>
  );
}

export default Card;
