import React, { useState } from 'react';
import './CustomSelect.scss';
import { useSelector, useDispatch } from 'react-redux';
import { changeCategory } from '../../store/categoriesSlice';

const CustomSelect = ({
  options,
  setSelectedScientificField,
  selectedScientificField,
  name,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

  const { selectedCategory } = useSelector((state) => state.categories);

  const dispatch = useDispatch();

  const handleSelect = (option) => {
    if (name === 'categories') {
      dispatch(changeCategory(option));
    }
    if (name === 'scientificFields') {
      setSelectedScientificField(option);
    }
    setShowOptions(false);
    setFocusedOptionIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setFocusedOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
    } else if (e.key === 'ArrowUp') {
      setFocusedOptionIndex(
        (prevIndex) => (prevIndex - 1 + options.length) % options.length
      );
    } else if (e.key === 'Enter' && focusedOptionIndex >= 0) {
      handleSelect(options[focusedOptionIndex]);
    }
  };

  return (
    <div
      className="custom-select"
      name={name}
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
      <div
        className={`select-box ${showOptions ? 'is-active' : ''}`}
        onClick={() => setShowOptions(!showOptions)}
      >
        {name === 'categories' && selectedCategory?.label}{' '}
        {name === 'scientificFields' && selectedScientificField?.label}
        <img
          className={
            showOptions
              ? 'custom-select__icon is-active'
              : 'custom-select__icon'
          }
          src="./../img/icons/icon-arrow.svg"
          alt=""
        />
      </div>
      <div className={`options-wrapper ${showOptions ? 'is-active' : ''}`}>
        <div className="options">
          {options?.map((option, index) => (
            <div
              key={option.value}
              className={`option ${
                index === focusedOptionIndex ? 'focused' : ''
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.icon ? (
                <div
                  className="option__icon"
                  style={{ backgroundColor: option.fill }}
                  dangerouslySetInnerHTML={{
                    __html: option.icon,
                  }}
                ></div>
              ) : (
                ''
              )}
              {option.label}{' '}
              {name === 'scientificFields' ? (
                <div className="custom-select__radio">
                  <div
                    className={
                      option.value === selectedScientificField.value
                        ? 'custom-select__radio-check is-active'
                        : 'custom-select__radio-check'
                    }
                  ></div>
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
