import React, { useEffect } from 'react';
import './BroadcastsEditor.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBroadcasts } from '../../store/broadcastsSlice';

const BroadcastsEditor = () => {
  const { broadcasts } = useSelector((state) => state.broadcasts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBroadcasts());
  }, []);

  return (
    <section className="broadcasts-editor">
      <h2 className="broadcasts-editor__title">Трансляции</h2>
      <ul className="broadcasts-editor__list">
        <li className="broadcasts-editor__list-desc">
          <div>№</div>
          <div>Название</div>
          <div>Описание</div>
          <div>Ссылка на сообщество</div>
          <div>Ссылка на видео</div>
        </li>
        {broadcasts?.map((broadcast, index) => (
          <li key={broadcast.id} className="broadcasts-editor__list-item">
            <div>{index + 1}</div>
            <div>{broadcast.name}</div>
            <div>{broadcast.description}</div>
            <div>{broadcast.link}</div>
            <div>{broadcast.video_link}</div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BroadcastsEditor;
