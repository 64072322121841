import { Link } from 'react-router';
import './NotFoundPage.scss';

function NotFoundPage() {
  return (
    <div className="not-found">
      <div className="container">
        <div className="not-found__error-wrapper">
          <h1 className="not-found__error">(404) (404) (404)</h1>
        </div>
        <div className="not-found__message">
          <p>Такой страницы не&nbsp;существует.</p>{' '}
          <p>
            Возможно, она была удалена, перемещена или никогда
            не&nbsp;существовала.
          </p>
        </div>

        <Link className="not-found__link" to="/">
          Вернуться на главную
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
