import { createSlice } from '@reduxjs/toolkit';

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [
      // { value: 'all', label: 'Все категории' },
      { value: 'discoveries', label: 'Открытия' },
      { value: 'broadcasts', label: 'Трансляции' },
    ],
    selectedCategory: { value: 'discoveries', label: 'Открытия' },
  },
  reducers: {
    changeCategory(state, action) {
      state.selectedCategory = action.payload;
    },
  },
});

export const { changeCategory } = categoriesSlice.actions;

export default categoriesSlice.reducer;
