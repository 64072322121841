import { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import * as api from '../../api/api';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const token = useSelector((state) => state.user.user.token);
  const role = useSelector((state) => state.user.user.role);

  return token && role === 'admin' ? <Outlet /> : <Navigate to="admin-login" />;
};

export default PrivateRoute;
