import { useState, useRef } from 'react';
import './HeaderEditor.scss';

function HeaderEditor() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploaded, setUpladed] = useState();

  const filePicker = useRef();

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/editlogo`, {
      method: 'POST',
      body: formData,
    });

    const data = await res.json();

    setUpladed(data);
  };

  const handlePick = () => {
    filePicker.current.click();
  };
  return (
    <div className="header-editor">
      <h1>HEADER EDITOR</h1>
      <h2>Логотип</h2>
      <button onClick={handlePick}>Pick file</button>
      <input
        ref={filePicker}
        className="hidden"
        type="file"
        onChange={handleChange}
        accept="image/*, .png, .jpg, .gif, .webp"
        name="image"
      />

      <button onClick={handleUpload}>Добавить</button>

      {selectedFile && (
        <ul>
          <li>Name: {selectedFile.name}</li>
          <li>Type: {selectedFile.type}</li>
          <li>Size: {selectedFile.size}</li>
          <li>
            LastModifiedDate:{' '}
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </li>
        </ul>
      )}

      {uploaded && (
        <div>
          <h2>{uploaded.fileName}</h2>
          <img src={uploaded.filePath} width="200" alt="" />
        </div>
      )}
    </div>
  );
}

export default HeaderEditor;
