import Partners from '../Partners/Partners';
import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top">
        <Partners />
      </div>
      <div className="footer__bottom">
        <div className="container">
          <ul className="footer__list">
            <li className="footer__list-item">
              <a href="#about" className="footer__link">
                О проекте
              </a>
            </li>
            <li className="footer__list-item">
              <a href="#map" className="footer__link">
                Карта
              </a>
            </li>
            <li className="footer__list-item">
              <a href="" className="footer__link">
                Моя страница
              </a>
            </li>
            <li className="footer__list-item">
              <a href="" className="footer__link footer__policy">
                Политика обработки персональных данных
              </a>
            </li>
            <li className="footer__list-item">
              <a href="/" className="footer__link">
                <img
                  className="footer__logo"
                  src="./../img/logo/logo-footer.png"
                  alt="Логотип ВНауке"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
