import './About.scss';

function About() {
  return (
    <div className="about" id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="about__top">
            <h1 className="about__title">О проекте</h1>
            <div className="about__img">
              <img
                src="./../img/content/about/about-01.svg"
                alt="Проект «Научные меридианы России»"
              />
            </div>
            <div className="about__main-text about__text">
              <p>
                В преддверии Дня российской науки компания VK совместно
                с Московским государственным университетом им. М.В. Ломоносова
                представляет уникальный образовательный проект — интерактивную
                карту научных открытий России.
              </p>
              <p>
                Эта карта — не просто собрание географических точек,
                это захватывающее путешествие в мир науки, позволяющее каждому
                проследить за историей научных достижений нашей страны.
                От первооткрывателей до современных новаторов,
                от фундаментальных исследований до практических приложений —
                карта показывает полную картину научного развития страны.
              </p>
            </div>
            <h2 className="about__subtitle">Особенности проекта:</h2>
            <div className="about__features">
              <ul className="about__features-list">
                <li className="about__features-list-item">
                  Создание этой карты стало возможным благодаря сотрудничеству
                  компании VK, МГУ и активному участию авторов
                  научно-популярных, технологических и образовательных сообществ
                  ВКонтакте. Мы собрали воедино знания и опыт лучших экспертов,
                  чтобы предоставить вам наиболее точную и увлекательную
                  информацию в формате VK Видео.
                </li>
                <li className="about__features-list-item">
                  Это живой и динамично развивающийся проект. Карта будет
                  на постоянной основе пополняться новыми точками, отражающими
                  как исторические, так и современные научные достижения.
                  В будущем мы планируем открыть карту для добавления контента
                  другими пользователями, чтобы сделать её по‑настоящему
                  народным проектом.
                </li>
                <li className="about__features-list-item">
                  Мы стремимся сделать науку доступной для каждого. Карта
                  разработана для всех устройств и будет интуитивно понятна
                  для пользователей любого возраста. Вы сможете легко найти
                  интересующие вас открытия, используя удобные фильтры
                  по тематике.
                </li>
              </ul>
            </div>
          </div>
          <div className="about__announce">
            <p>
              7 февраля в рамках Всероссийской акции «Ученые — в школы»
              вы сможете стать участником уникального марафона научных лекций,
              транслируемых в режиме реального времени прямо на карте.
            </p>
            <p>
              11 лекций от ведущих российских ученых будут разделены
              по 11 часовым поясам, чтобы каждый школьник страны мог принять
              участие в наиболее удобное для себя время (14:00 по местному
              времени). Просто переключите карту в режим трансляций!
            </p>
          </div>
          <div className="about__plans about__text">
            <p>
              В будущем мы планируем добавить функции, которые позволят
              пользователям создавать свои собственные маршруты, собирать
              коллекции открытий и делиться своими находками с друзьями.
            </p>
          </div>
          <div className="about__text about__text--accent">
            <p>
              Мы надеемся, что наша интерактивная карта станет не только ценным
              образовательным ресурсом, но и вдохновляющим источником знаний
              для всех, кто интересуется наукой и историей своей страны.
            </p>
          </div>
          <a className="about__link" href="/#map">
            Смотреть карту
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
