import About from '../components/About/About';
import { useSelector } from 'react-redux';
import Map4 from '../components/Map/Map4';
import MapEdit2 from '../components/Map/MapEdit2';

function HomePage() {
  const { selectedCategory } = useSelector((state) => state.categories);
  return (
    <>
      <div className="map" id="map">
        <div
          className={
            selectedCategory.value === 'broadcasts'
              ? 'map__background broadcasts-bg'
              : 'map__background'
          }
        >
          <img src="./../img/content/bg/meridian.svg" alt="background" />
        </div>
        {/* <div className="container"> */}
        <Map4 />
        {/* </div> */}
      </div>
      <About />
      {/* <MapEdit2 /> */}
    </>
  );
}

export default HomePage;
