import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/style.scss';
import HomePage from './pages/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import MainLayout from './layouts/MainLayout';
import AdminPage from './pages/AdminPage/AdminPage';
import PrivateRoute from './utils/router/privateRoute';
import { Provider } from 'react-redux';
import store from './store';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import AdminLoginPage from './pages/LoginPage/AdminLoginPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  return null;
};
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToHash />
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/broadcasts/:id" element={<HomePage />} />
          <Route path="/discoveries/:id" element={<HomePage />} />
          <Route path="/admin-login" element={<AdminLoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="admin/*" element={<AdminPage />}></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);
