import { request, requestWithToken } from '../http/http.js';

export function getItems() {
  return request(`${process.env.REACT_APP_API_URL}/items`, {});
}

export function getBroadcasts() {
  return request(`${process.env.REACT_APP_API_URL}/broadcasts`, {});
}

export function getItem(id) {
  return request(`${process.env.REACT_APP_API_URL}/items/${id}`, {});
}

export function getBroadcast(id) {
  return request(`${process.env.REACT_APP_API_URL}/broadcasts/${id}`, {});
}

export function getScientificFields() {
  return request(`${process.env.REACT_APP_API_URL}/scientific_fields`, {});
}

export function getHeaderContent() {
  return request(`${process.env.REACT_APP_API_URL}/header`, {});
}

export function getUserInfo() {
  return requestWithToken(`${process.env.REACT_APP_API_URL}/user`, {});
}

export function addLocation(options) {
  return requestWithToken(`${process.env.REACT_APP_API_URL}/location`, options);
}

export function addBroadcast(options) {
  return requestWithToken(
    `${process.env.REACT_APP_API_URL}/broadcast`,
    options
  );
}

export function login(options) {
  return requestWithToken(`${process.env.REACT_APP_API_URL}/users`, options);
}

export function logout(options) {
  return requestWithToken(`${process.env.REACT_APP_API_URL}/logout`, options);
}

export function getCities() {
  return request(`${process.env.REACT_APP_API_URL}/cities`, {});
}

export function getFilteredCities(id) {
  return request(`${process.env.REACT_APP_API_URL}/cities/${id}`, {});
}
