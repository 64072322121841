import React, { useRef, useState, useEffect, useCallback } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import MapSvg2 from './MapSvg2';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBroadcasts } from '../../store/broadcastsSlice';
import { fetchCities } from '../../store/citiesSlice';
import Filters from '../Filters/Filters';
import * as api from '../../api/api';
import Card from '../Card/Card';
import { useMediaQuery } from 'react-responsive';

const Controls = React.memo(({ zoomIn, zoomOut }) => (
  <div className="map__controls">
    <button
      className="map__control-btn map__control-btn--plus"
      onClick={() => zoomIn()}
    >
      <img src="./../img/icons/icon-plus.svg" alt="Плюс" />
    </button>
    <button
      onClick={() => zoomOut()}
      className="map__control-btn map__control-btn--minus"
    >
      <img src="./../img/icons/icon-minus.svg" alt="Минус" />
    </button>
  </div>
));

const Map4 = () => {
  const dispatch = useDispatch();
  const transformWrapperRef = useRef(null);
  const citiesBlockRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [isPanningDisabled, setIsPanningDisabled] = useState(false);
  const [locations, setLocations] = useState([]);
  const [filterBtnIsActive, setFilterBtnIsActive] = useState(false);
  const [activeCityId, setActiveCityId] = useState(null);

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const cardRef = useRef(null);

  const { selectedCategory } = useSelector((state) => state.categories);
  const [selectedScientificField, setSelectedScientificField] = useState({
    value: 'all',
    label: 'Все области науки',
  });

  const MIN_SCALE_FACTOR = 0.3;
  const HIDE_THRESHOLD = 69.998;

  useEffect(() => {
    dispatch(fetchBroadcasts());
    dispatch(fetchCities());
  }, [dispatch]);

  const { broadcasts } = useSelector((state) => state.broadcasts);
  const { cities } = useSelector((state) => state.cities);

  const [filteredCities, setFilteredCities] = useState(cities);

  useEffect(() => {
    (async function () {
      const updatedCities = await api.getFilteredCities(
        selectedScientificField.value
      );
      setFilteredCities(updatedCities);

      if (isPanningDisabled && activeCityId) {
        const location = updatedCities.filter(
          (city) => city.id === activeCityId
        );
        setLocations(location[0]?.locations);
      }
    })();
  }, [selectedScientificField, isPanningDisabled, activeCityId]);

  function filterLocation(id) {
    const location = filteredCities.filter((city) => city.id === id);
    setLocations(location[0]?.locations);
  }

  const zoomToImage = (e, id) => {
    if (transformWrapperRef.current) {
      const { zoomToElement } = transformWrapperRef.current;
      zoomToElement(id, 70);
      setScale(70);

      let dot = e.target.closest('.dot');
      if (dot) {
        dot.classList.add('hidden');
      }

      filterLocation(id);
      setActiveCityId(id);

      citiesBlockRef.current.classList.add('is-active');
      setIsPanningDisabled(true);
    }
  };

  const zoomIn = useCallback(() => {
    if (transformWrapperRef.current) {
      const { zoomIn } = transformWrapperRef.current;
      zoomIn(0.5, 600, 'easeOut');
      setScale((prevScale) => Math.min(prevScale * 1.2, 100));
    }
  }, [transformWrapperRef]);

  const zoomOut = useCallback(() => {
    if (transformWrapperRef.current) {
      if (isPanningDisabled) {
        transformWrapperRef.current.resetTransform();
        setScale(1);
        setIsPanningDisabled(false);
        citiesBlockRef.current.classList.remove('is-active');
        if (!isSmallScreen) {
          setTimeout(() => {
            transformWrapperRef.current.centerView(1, 50);
          }, 301);
        }
      } else {
        const { zoomOut } = transformWrapperRef.current;
        const newScale = Math.max(scale * 0.8, MIN_SCALE_FACTOR);

        if (newScale > 1) {
          zoomOut(0.5, 600, 'easeOut');
          setScale(newScale);
        } else {
          setScale(1);
          transformWrapperRef.current.setTransform(0, 0, 1);
          setTimeout(() => {
            transformWrapperRef.current.centerView(1, 50);
          }, 100);
        }
      }
    }
  }, [transformWrapperRef, isPanningDisabled, scale]);

  const [card, setCard] = useState({});

  const [cardIsActive, setCardIsActive] = useState(false);

  async function broadcastClickHandler(id) {
    setCard(await api.getBroadcast(id));
    setCardIsActive(true);
  }

  async function locationClickHandler(id) {
    setCard(await api.getItem(id));
    setCardIsActive(true);
  }
  const handleWheelStart = (e) => {
    if (isPanningDisabled) {
      setTimeout(() => {
        transformWrapperRef.current.resetTransform();
        setScale(1);
        setIsPanningDisabled(false);
        if (!isSmallScreen) {
          setTimeout(() => {
            transformWrapperRef.current.centerView(1, 50);
          }, 301);
        }
      }, 300);

      citiesBlockRef.current.classList.remove('is-active');
    }
  };

  useEffect(() => {
    if (isPanningDisabled) {
      if (scale < HIDE_THRESHOLD) {
        setIsPanningDisabled(false);
        citiesBlockRef.current.classList.remove('is-active');
      }
    }
  }, [scale]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        cardIsActive &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        setCardIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [cardIsActive]);

  const openFilterBtnHandler = () => {
    setFilterBtnIsActive(!filterBtnIsActive);
  };

  useEffect(() => {
    if (isPanningDisabled) {
      transformWrapperRef.current.resetTransform();
      setScale(1);
      setIsPanningDisabled(false);
      citiesBlockRef.current.classList.remove('is-active');
      if (!isSmallScreen) {
        setTimeout(() => {
          transformWrapperRef.current.centerView(1, 50);
        }, 301);
      }
    }
  }, [selectedCategory]);

  const handleMapClick = (event) => {
    const wrapperBounds = event.currentTarget.getBoundingClientRect();

    const x =
      ((event.clientX - wrapperBounds.left) / wrapperBounds.width) * 100;
    const y =
      ((event.clientY - wrapperBounds.top) / wrapperBounds.height) * 100;

    console.log(x, 'x');
    console.log(y, 'y');
  };

  return (
    <div className="map__wrapper">
      <Filters
        selectedScientificField={selectedScientificField}
        setSelectedScientificField={setSelectedScientificField}
        filterBtnIsActive={filterBtnIsActive}
      />
      <div className="map__controls-wrapper">
        <button
          onClick={() => openFilterBtnHandler()}
          className={
            filterBtnIsActive ? 'map__filter-btn is-active' : 'map__filter-btn'
          }
        >
          <div className="map__filter-btn--open">
            <img src="./../img/icons/icon-filter.svg" alt="Открыть фильтры" />
          </div>
          <div className="map__filter-btn--close">
            <img src="./../img/icons/icon-close.svg" alt="Закрыть фильтры" />
          </div>
        </button>
        <Controls zoomIn={zoomIn} zoomOut={zoomOut} />
      </div>
      <div ref={cardRef}>
        <Card
          cardIsActive={cardIsActive}
          setCardIsActive={setCardIsActive}
          card={card}
        />
      </div>
      <TransformWrapper
        initialScale={1}
        maxScale={100}
        initialPositionX={0}
        initialPositionY={isSmallScreen ? 0 : 60}
        limitToBounds={false}
        minScale={1}
        ref={transformWrapperRef}
        onZoom={(event) => {
          setScale(event.state.scale);
        }}
        panning={{ disabled: isPanningDisabled }}
        onWheel={handleWheelStart}
        wheel={{
          step: 20,
          smoothStep: 0.005,
          activationKeys: ['Control', 'Shift'],
        }}
        centerOnInit={isSmallScreen ? false : true}
        centerView={true}
      >
        {(utils) => (
          <React.Fragment>
            <TransformComponent>
              <div
                className="map__svg-wrapper"
                onClick={(e) => handleMapClick(e)}
              >
                <MapSvg2 />

                {selectedCategory.value === 'broadcasts' &&
                  broadcasts.map((broadcast) => (
                    <div
                      key={broadcast.id}
                      className="map-dot-broadcast dot"
                      dangerouslySetInnerHTML={{ __html: broadcast.icon }}
                      style={{
                        background: broadcast.fill,
                        left: `calc(${broadcast.coordinateX}% - 14px)`,
                        top: `calc(${broadcast.coordinateY}% - 14px)`,
                        // transform: `scale(${Math.max(
                        //   MIN_SCALE_FACTOR,
                        //   1 / scale
                        // )})`,
                        transform: `scale(${Math.min(
                          1,
                          Math.max(MIN_SCALE_FACTOR, 1 / scale)
                        )})`,
                      }}
                      onClick={() => broadcastClickHandler(broadcast.id)}
                    ></div>
                  ))}
                {selectedCategory.value === 'discoveries' &&
                  filteredCities?.map((city) =>
                    city.locations.length > 1 ? (
                      <div
                        key={city.id}
                        onClick={(e) => zoomToImage(e, city.id)}
                        className={
                          isPanningDisabled && scale > HIDE_THRESHOLD
                            ? 'map-dot-cities dot hidden'
                            : 'map-dot-cities dot'
                        }
                        id={city.id}
                        style={{
                          left: `${city.coordinateX}%`,
                          top: `${city.coordinateY}%`,
                          // transform: `scale(${Math.max(
                          //   MIN_SCALE_FACTOR,
                          //   1 / scale
                          // )})`,
                          transform: `scale(${Math.min(
                            1,
                            Math.max(MIN_SCALE_FACTOR, 1 / scale)
                          )})`,
                        }}
                      >
                        <span>{city?.locations?.length}</span>
                      </div>
                    ) : (
                      <div
                        key={city.id}
                        className={
                          isPanningDisabled
                            ? 'map-dot-city dot hidden'
                            : 'map-dot-city dot'
                        }
                        dangerouslySetInnerHTML={{
                          __html: city.locations[0].icon,
                        }}
                        style={{
                          backgroundColor: city.locations[0].fill,
                          left: `calc(${city.coordinateX}% + 16px)`,
                          top: `calc(${city.coordinateY}% + 16px)`,
                          // transform: `scale(${Math.max(
                          //   MIN_SCALE_FACTOR,
                          //   1 / scale
                          // )})`,
                          transform: `scale(${Math.min(
                            1,
                            Math.max(MIN_SCALE_FACTOR, 1 / scale)
                          )})`,
                        }}
                        onClick={() =>
                          locationClickHandler(city.locations[0].locations_id)
                        }
                      ></div>
                    )
                  )}
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      <div
        className={
          isPanningDisabled && scale < HIDE_THRESHOLD
            ? 'map__cities-block'
            : 'map__cities-block'
        }
        ref={citiesBlockRef}
      >
        {locations?.map((location) => (
          <div
            key={location.locations_id}
            className="map-dot-location-test dot"
            dangerouslySetInnerHTML={{
              __html: location.icon,
            }}
            style={{ backgroundColor: location.fill }}
            onClick={() => locationClickHandler(location.locations_id)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Map4;
