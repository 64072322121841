import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import broadcastsReducer from './broadcastsSlice';
import discoveriesReducer from './discoveriesSlice';
import categoriesReducer from './categoriesSlice';
import citiesReducer from './citiesSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    broadcasts: broadcastsReducer,
    discoveries: discoveriesReducer,
    categories: categoriesReducer,
    cities: citiesReducer,
  },
});
